import { Logtail } from "@logtail/browser";
import debugModule from "debug";

import { isPlainObject, uuid } from "./utils";

const logtailKey = {
  production: "keyAcZRGmo8z7tzNJrHeqvwy",
  staging: "siDe8z8pRcpMPj8Gp93CmCe4",
}[process.env.NODE_ENV ?? "development"];

const logtailEndpoint = {
  production: "https://log-intake.upscope.io/",
  staging: "https://log-intake.upscope.xyz/",
}[process.env.NODE_ENV ?? "development"];

const pageviewId = uuid();
let context: Record<string, any> = {
  release: process.env.CURRENT_APP_VERSION,
  userId: (window as any).userId,
  companyId: (window as any).companyId,
  pageviewId,
};

const logtail = logtailKey
  ? new Logtail(logtailKey, {
      endpoint: logtailEndpoint,
      syncMax: 1,
      burstProtectionMax: 250,
      burstProtectionMilliseconds: 5_000,
    })
  : null;

export function setLoggerContext(newContext: Record<string, any>) {
  context = { ...context, ...newContext };
}

export default function logger(
  namespace: string,
  throttle: { max: number; seconds: number } = { max: 30, seconds: 5 },
) {
  const debug = debugModule(`upscope:${namespace}`);
  const debugWarning = debugModule(`upscope:${namespace}:warn`);

  let currentThrottle = 0;

  function isThrottled() {
    if (currentThrottle === 0) {
      setTimeout(() => {
        if (currentThrottle > throttle.max)
          logtail?.warn(`Dropped ${currentThrottle - throttle.max} logs due to throttle`, { ...context, namespace });

        currentThrottle = 0;
      }, throttle.seconds * 1000);
    }

    return currentThrottle++ > throttle.max;
  }

  const safeJsonStringify = (obj: any) => {
    if (!isPlainObject(obj)) return String(obj);
    try {
      return JSON.stringify(obj);
    } catch (e) {
      return `Unserializable object: ${e}`;
    }
  };
  const func = function (message: string, ...args: any[]) {
    debug(message, ...args);
    if ((window as any).baa === true) return;
    if (isThrottled()) return;

    args = args.map((arg) => safeJsonStringify(arg).slice(0, 200));
    const logArgs = args.slice();
    logtail?.info(
      message.replace(/%[sodO]/g, () => logArgs.shift().slice(0, 30)),
      {
        arguments: args,
        namespace,
        ...context,
      },
    );
  };

  func.local = function (message: string, ...args: any[]) {
    debug(message, ...args);
  };

  func.warning = function (message: string, ...args: any[]) {
    debugWarning(message, ...args);
    if ((window as any).baa === true) return;
    if (isThrottled()) return;

    args = args.map((arg) => safeJsonStringify(arg).slice(0, 200));
    const logArgs = args.slice();
    logtail?.warn(
      message.replace(/%[sodO]/g, () => logArgs.shift().slice(0, 30)),
      {
        arguments: args,
        namespace: `${namespace}:warn`,
        ...context,
      },
    );
  };

  return func;
}
