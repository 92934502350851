import { z } from "zod";

import { PlanDetails } from "~data/PlanDetails";

import { zDay } from "../../utils/types";
import { ContactChannel } from "./partials/ContactChannel";
import { CountryCode } from "./partials/CountryCode";
import { CustomerSuccessRating } from "./partials/CustomerSuccessRating";
import { CustomerSuccessStage } from "./partials/CustomerSuccessStage";
import { InstallationStatus } from "./partials/InstallationStatus";
import { PaymentSourceData } from "./partials/PaymentSourceData";
import { Product } from "./partials/Product";
import { UsageTrend, UsageTrendData } from "./partials/UsageTrend";
import { UsStateCode } from "./partials/UsStateCode";
import { Plan } from "./Plan";
import { User, UserBaseData } from "./User";

export const Team = z.object({
  id: z.custom<`t_${string}`>(),
  createdAt: z.coerce.date(),
  deletable: z.boolean(),
  domain: z.string(),
  emailDomain: z.string().nullable(),
  apiKey: z.string(),
  uniqueIdentifier: z.string(),
  paying: z.boolean(),
  trialEndsOn: zDay.nullable(),
  trialConvertsAutomatically: z.boolean().nullable(),
  type: z.literal("team"),
  beta: z.boolean(),
  baa: z.boolean(),
  nextPlanLicenses: z.number().nullable(),
  onboardingCompleted: z.boolean(),
  products: z.array(Product),
  signupProduct: Product.nullable(),
  usageTrendData: UsageTrendData.nullable(),
  usageTrend: UsageTrend.nullable(),
  yearlyValue: z.number(),
  activeLicenses: z.number(),
  licensesNeeded: z.number(),
  autoAdjustLicenses: z.enum(["off", "increase", "increase_and_decrease", "not_allowed", "bill_monthly"]),
  onTrial: z.boolean(),
  trialStartedOn: z.coerce.date().nullable(),
  cancelling: z.boolean(),
  plan: Plan.nullable(),
  planDetails: z.custom<PlanDetails>().nullable(),
  nextPlan: Plan.nullable(),
  nextPlanDetails: z.custom<PlanDetails>().nullable(),
  nextPlanActivatesOn: zDay.nullable(),
  sessionTimeCreditSecondsLeft: z.number().nullable(),
  contractAutoRenews: z.boolean(),
  contractTerminatesOn: zDay.nullable(),
  banReason: z.enum(["delinquent", "security", "other"]).nullable(),
  onPremise: z.boolean(),
  onPremiseRequireServerValidation: z.boolean(),
  onPremiseAllowedDomains: z.array(z.string()).nullable(),
  newMembersGracePeriodDays: z.number(),
  canMakePlanChanges: z.boolean(),
  billingPausedUntil: z.coerce.date().nullable(),
  billingPeriodEndsOn: zDay.nullable(),
  freeAccount: z.boolean(),
  invoiceName: z.string().nullable(),
  invoiceAddress: z.string().nullable(),
  invoiceCountry: CountryCode.nullable(),
  country: CountryCode.nullable(),
  invoiceEmail: z.string().nullable(),
  invoiceZip: z.string().nullable(),
  invoiceState: UsStateCode.nullable(),
  vatNumber: z.string().nullable(),
  invoiceDaysUntilDue: z.number(),
  banned: z.boolean(),
  trialProduct: Product.nullable(),
  membersCount: z.number(),
  subscriptionPaused: z.boolean(),
  subscribedAt: z.coerce.date().nullable(),
  subscriptionEndsAt: z.coerce.date().nullable(),
  taxRate: z.number(),
  lastCustomerSuccessCheckupAt: z.coerce.date().nullable(),
  lastCustomerSuccessCheckupRating: CustomerSuccessRating.nullable(),
  currentCustomerSuccessStage: CustomerSuccessStage,
  currency: z.enum(["usd", "gbp", "eur"]).nullable(),
  customerSuccessStageChangeableTo: z.array(CustomerSuccessStage),
  customerSuccessCheckupDue: z.boolean(),
  customerSuccessCheckupSnoozedUntil: z.coerce.date().nullable(),
  csUsageTrendData: UsageTrendData.nullable(),
  customerSuccessManager: UserBaseData.nullable(),
  csInstallationStatus: InstallationStatus,
  installationStatus: InstallationStatus,
  lastRequestAt: z.coerce.date().nullable(),
  lastUsedAt: z.coerce.date().nullable(),
  isInstalled: z.boolean(),
  stillInstalled: z.boolean(),
  isInstalledLive: z.boolean(),
  unsubscribedAt: z.coerce.date().nullable(),
  billingOnboardingCompleted: z.boolean(),
  nextBillingPoint: zDay.nullable(),

  settingSessionTimeCreditAutoTopUpEnabled: z.boolean(),
  settingSessionTimeCreditAutoTopUpTrigger: z.number(),
  settingSessionTimeCreditAutoTopUpMultiplier: z.number(),

  paymentSourceData: PaymentSourceData.nullable(),
  billingOwner: z
    .object({
      id: z.string(),
      domain: z.string(),
      uniqueIdentifier: z.string(),
      type: z.literal("team"),
    })
    .nullable(),
  billingSubaccounts: z.array(
    z.object({
      id: z.string(),
      domain: z.string(),
      uniqueIdentifier: z.string(),
      type: z.literal("team"),
    }),
  ),

  owner: User.pick({
    id: true,
    email: true,
    name: true,
    type: true,
  }),
  ownerId: z.custom<`u_${string}`>(),
});

export type Team = z.infer<typeof Team>;

export const TeamBaseData = Team.pick({
  id: true,
  domain: true,
  type: true,
  uniqueIdentifier: true,
});

export type TeamBaseData = z.infer<typeof TeamBaseData>;

export const TeamAdminViewData = TeamBaseData.merge(
  Team.pick({
    customerSuccessManager: true,
    createdAt: true,
    activeLicenses: true,
    nextPlanLicenses: true,
    apiKey: true,
    licensesNeeded: true,
    autoAdjustLicenses: true,
    onTrial: true,
    trialEndsOn: true,
    currency: true,
    trialStartedOn: true,
    trialConvertsAutomatically: true,
    cancelling: true,
    plan: true,
    planDetails: true,
    nextPlan: true,
    nextPlanDetails: true,
    nextPlanActivatesOn: true,
    sessionTimeCreditSecondsLeft: true,
    contractAutoRenews: true,
    contractTerminatesOn: true,
    beta: true,
    baa: true,
    banReason: true,
    onPremise: true,
    onPremiseRequireServerValidation: true,
    onPremiseAllowedDomains: true,
    products: true,
    newMembersGracePeriodDays: true,
    canMakePlanChanges: true,
    billingPausedUntil: true,
    billingPeriodEndsOn: true,
    country: true,
    freeAccount: true,
    invoiceName: true,
    invoiceAddress: true,
    invoiceZip: true,
    invoiceState: true,
    taxRate: true,
    invoiceCountry: true,
    currentCustomerSuccessStage: true,
    customerSuccessStageChangeableTo: true,
    invoiceEmail: true,
    vatNumber: true,
    invoiceDaysUntilDue: true,
    subscriptionPaused: true,
    subscribedAt: true,
    subscriptionEndsAt: true,
    paymentSourceData: true,
    paying: true,
    billingOwner: true,
    billingSubaccounts: true,
    owner: true,
    installationStatus: true,
    nextBillingPoint: true,
    deletable: true,
  }),
);

export type TeamAdminViewData = z.infer<typeof TeamAdminViewData>;

export const TeamCustomerSuccessData = TeamBaseData.merge(
  Team.pick({
    lastCustomerSuccessCheckupAt: true,
    lastCustomerSuccessCheckupRating: true,
    currentCustomerSuccessStage: true,
    customerSuccessCheckupDue: true,
    customerSuccessCheckupSnoozedUntil: true,
    customerSuccessStageChangeableTo: true,
    csUsageTrendData: true,
    customerSuccessManager: true,
    lastUsedAt: true,
    lastRequestAt: true,
    usageTrend: true,
    emailDomain: true,
    products: true,
    csInstallationStatus: true,
    onTrial: true,
    subscriptionEndsAt: true,
    unsubscribedAt: true,
    subscribedAt: true,
  }),
).merge(
  z.object({
    company: z.object({
      id: z.string(),
      type: z.literal("company"),
      username: z.string(),
      yearlyValue: z.number(),
      lastHeardFromAt: z.coerce.date().nullable(),
      lastContactedAt: z.coerce.date().nullable(),
      lastHeardFromChannel: ContactChannel.nullable(),
      lastContactedChannel: ContactChannel.nullable(),
      selfService: z.boolean().nullable(),
    }),
  }),
);

export type TeamCustomerSuccessData = z.infer<typeof TeamCustomerSuccessData>;

export const TeamBillingInfoData = TeamBaseData.merge(
  Team.pick({
    products: true,
    activeLicenses: true,
    autoAdjustLicenses: true,
    licensesNeeded: true,
    plan: true,
    freeAccount: true,
    billingPausedUntil: true,
    sessionTimeCreditSecondsLeft: true,
    canMakePlanChanges: true,
    cancelling: true,
    contractAutoRenews: true,
    contractTerminatesOn: true,
    nextPlanActivatesOn: true,
    nextPlan: true,
    onTrial: true,
    subscriptionEndsAt: true,
    trialConvertsAutomatically: true,
    trialEndsOn: true,
    trialProduct: true,
    subscriptionPaused: true,
    trialStartedOn: true,
    paymentSourceData: true,
    billingOnboardingCompleted: true,
    invoiceAddress: true,
    invoiceCountry: true,
    invoiceEmail: true,
    invoiceName: true,
    invoiceZip: true,
    invoiceState: true,
    vatNumber: true,
    billingOwner: true,
    settingSessionTimeCreditAutoTopUpEnabled: true,
    settingSessionTimeCreditAutoTopUpTrigger: true,
    settingSessionTimeCreditAutoTopUpMultiplier: true,
    taxRate: true,
  }),
);

export type TeamBillingInfoData = z.infer<typeof TeamBillingInfoData>;
