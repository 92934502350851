import { z } from "zod";

import { Company } from "./Company";

export const Quote = z.object({
  id: z.string(),
  publicKey: z.string(),
  status: z.enum([
    "draft",
    "published",
    "awaiting_signature",
    "signed",
    "trial",
    "trial_canceled",
    "completed",
    "deleted",
    "paid",
  ]),
  signerName: z.string(),
  signerEmail: z.string(),
  company: Company.pick({ id: true, type: true, name: true }),
  type: z.literal("quote"),
});

export type Quote = z.infer<typeof Quote>;

export const QuoteAdminSearchData = Quote.pick({
  id: true,
  publicKey: true,
  company: true,
  status: true,
  signerEmail: true,
  signerName: true,
  type: true,
});

export type QuoteAdminSearchData = z.infer<typeof QuoteAdminSearchData>;
